"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.remove = exports.update = exports.create = exports.findOne = exports.findAllCompeticoesAtivasPublica = exports.findAllCompeticoesAtivas = exports.findAll = void 0;
const core_1 = require("./core");
function findAll(page = 1, search) {
    return core_1.get('CompCompeticao', 'lista', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAll = findAll;
function findAllCompeticoesAtivas(page = 1, search) {
    return core_1.get('CompCompeticao', 'listaCompeticaoAtiva', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAllCompeticoesAtivas = findAllCompeticoesAtivas;
function findAllCompeticoesAtivasPublica(page = 1, idComp, search) {
    return core_1.get('CompCompeticao', 'listaCompeticaoAtivaPublica', Object.assign({ pg: page, id: idComp }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAllCompeticoesAtivasPublica = findAllCompeticoesAtivasPublica;
function findOne(id) {
    return core_1.get('CompCompeticao', 'detalhe', { id });
}
exports.findOne = findOne;
function create(data) {
    return core_1.post('CompCompeticao', 'cadastro', data);
}
exports.create = create;
function update(id, data) {
    return core_1.put('CompCompeticao', 'update', Object.assign({ id }, data));
}
exports.update = update;
function remove(id) {
    return core_1.del('CompCompeticao', 'exclui', { id });
}
exports.remove = remove;

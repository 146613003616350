<template>
<v-row>
  <!--dados da cpmpetição-->
  <v-col cols="12" class="">
    <v-card class="px-3">
      <v-card-text class="subtitle-1 font-weight-light">
        <v-row>  
          <v-col cols="12" sm="4">
            <search-box
                label="* Evento"
                popup-label="Selecione um Evento"
                :id.sync="value.idCompEvento"
                :descricao.sync="value.descCompEvento"
                :value="evento"
                :loader-fn="loaderEventos"
                item-key="id"
                item-text="descricao"
                :rules="[
                      this.$validators.notNullOrUndefined
                  ]">
              <template v-slot:empty>
                <p class="ma-2">
                  Nenhum Evento encontrado
                </p>
              </template>
            </search-box>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field dense label="* Ano Competência" 
                  v-model="value.anoCompetencia"
                  :rules="[
                      this.$validators.number.required,
                      v => this.$validators.number.greaterThanOrEquals(v, this.anoPermitidoInicio()),
                      v => this.$validators.number.lessThanOrEquals(v, this.anoPermitidoFim())
                  ]" type="number"/>
          </v-col>  
          <v-col cols="12" sm="4">
            <search-box
                label="* Etapa"
                popup-label="Selecione uma Etapa"
                :id.sync="value.idCompEtapa"
                :descricao.sync="value.descCompEtapa"
                :value="etapa"
                :loader-fn="loaderEtapas"
                item-key="id"
                item-text="descricao"
                :rules="[
                      this.$validators.notNullOrUndefined
                  ]">
              <template v-slot:empty>
                <p class="ma-2">
                  Nenhuma Etapa encontrada
                </p>
              </template>
            </search-box>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field label="* Data Inicio Insc."
                dense
                type="date"
                v-model="value.dtInicioInscricao"
                :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.lessThanOrEquals(v, 10)
                ]"/>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field label="* Data Fim Insc."
                  dense
                  type="date"
                  v-model="value.dtFimInscricao"
                  :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.lessThanOrEquals(v, 10)
                  ]"/>
          </v-col>
          <v-col cols="12" sm="4">
            <lookup-dominio label="* Status"
                            v-model="value.status"
                            :type="lookups.status"
                            :hidden-clear="false"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
  <!--dados da cpmpetição-->
  <v-col cols="12" class="">
    <v-card class="px-3">
      <v-card-title>
        <v-icon left color="red">mdi-cog-outline</v-icon>
        <span class="title">Configurações</span>
      </v-card-title>      
      <v-card-text class="subtitle-1 font-weight-light">
        <v-row>
          <v-col cols="12" sm="9">
            <lookup-dominio hint="* Tipo Comp"
                            :radio="true"
                            v-model="value.flgTipoComp"
                            :type="lookups.flgTipoComp"
                            :hidden-clear="false"/>
          </v-col>
          <v-col cols="12" sm="3" class="d-flex align-end justify-end">
            <v-btn small color="primary" @click="setModeloPadrao()">
              Modelo Padrão
            </v-btn>                         
          </v-col>            
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field 
              :disabled="!this.flgValorUnico"
              label="* Valor Único"
              prefix="R$"
              :rules="[
                  v => this.$validators.number.greaterThanOrEquals(v, 0)
              ]"
              v-model="value.valorUnico" type="number"/>
          </v-col>
          <v-col cols="12" sm="4" v-if="value.flgTipoComp === 'UN'">
            <v-switch
              v-model="flgValorUnico"
              label="Replicar Valor Único?"
            ></v-switch>
          </v-col>            
          <v-col cols="12" sm="4" v-show="value.flgTipoComp ==='CA'">
            <v-text-field 
              label="* Valor Promocional"
              prefix="R$"
              :rules="[
                  v => this.$validators.number.greaterThanOrEquals(v, 0)
              ]"
              v-model="value.valorPromocional" type="number"/>
          </v-col>    
        </v-row>
        <v-row v-if="this.value.flgTipoComp !== 'SO'">
          <v-col cols="12" sm="12">
            <CardPersonalizado 
              :title="tituloOpcoes.un"
              icon="playlist-plus"
              :action="addUnicas"
              >
              <v-row v-for="(unica, index) in value.unicas" :key="index">                
                <v-col cols="12" sm="6">
                  <v-text-field label="* Descrição"
                              dense
                              v-model.trim="unica.descricao"/>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field label="* Valor"
                                dense
                                :disabled="flgValorUnico"
                                v-model="unica.valor"
                                type="number"/>
                </v-col>
                <v-col cols="12" sm="2" class="d-flex align-end justify-end">
                  <v-btn small color="error" @click="removeUnicas(index)">
                    <v-icon>mdi-playlist-remove</v-icon>
                  </v-btn>                         
                </v-col>                           
              </v-row>
            </CardPersonalizado>
          </v-col>
        </v-row>            
      </v-card-text>
    </v-card>
  </v-col>
</v-row>
</template>

<script>
import SearchBox from "../common/SearchBox";
import {findAll as findAllEventos} from "../../../api/eventos";
import {findAll as findAllEtapas} from "../../../api/etapas";
import LookupDominio from "../common/LookupDominio";
import CardPersonalizado from "../common/CardPersonalizado";
import {ItemDeDominio} from "../../../api/itemsDominio";
import moment from 'moment';

export default {
  name: "CompeticaoForm",
  components: {LookupDominio, SearchBox, CardPersonalizado},
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      lookups: {
        status: ItemDeDominio.STATUS_PADRAO,
        flgTipoComp: ItemDeDominio.FLG_TIPO_COMP
      },
      flgValorUnico: false,
      tituloOpcoes: {un: '', ca:''}
    }
  },
  watch: {
    'value.flgTipoComp': {
      deep: true,
      handler(newValue) {
        if (newValue === 'CA' || newValue === 'SO' ) this.flgValorUnico = true;
        if (newValue === 'UN') this.tituloOpcoes = {un: 'Opções', ca:''};
        if (newValue === 'CA') this.tituloOpcoes = {un: 'Opções 1',ca: 'Opções 2'};
        if (newValue === 'SO') this.tituloOpcoes = {un: '', ca:''}
      }
    },
    flgValorUnico: {
      deep: true,
      handler(newValue) {
        console.log('acessou watch.flgValorUnico');
        this.value.flgValorUnico = (newValue ? 'S' : 'N');
        console.log('flgValorUnico '+newValue+'');
        if (!newValue) {
          this.value.valorUnico = null;
        } else {
          this.replicaValoresOpcoes(this.value.valorUnico);
        }
      }
    },
    'value.valorUnico': {
      deep: true,
      handler(newValue) {
        if (this.flgValorUnico){
          this.replicaValoresOpcoes(newValue);
        }
      }
    },    
  },  
  computed: {
    evento() {
      if(this.value.idCompEvento == null) return null;
      return {
        id: this.value.idCompEvento,
        descricao: this.value.descCompEvento
      }
    },
    etapa() {
      if(this.value.idCompEtapa == null) return null;
      return {
        id: this.value.idCompEtapa,
        descricao: this.value.descCompEtapa
      }
    },
  },
  methods: {
    replicaValoresOpcoes(valorUnico){
      console.log('acessou replicaValoresOpcoes ' + valorUnico);
      if (this.value.flgTipoComp !== 'SO'){
        this.value.unicas = this.value.unicas.map(item => ({
          ...item,
          valor: valorUnico
        }));
        this.value.casadinhas = this.value.casadinhas.map(item => ({
          ...item,
          valor: valorUnico
        }));
      }
    },
    loaderEventos(page, search) {
      return findAllEventos(page, {
        descricao: search
      })
    },
    loaderEtapas(page, search) {
      return findAllEtapas(page, {
        descricao: search
      })
    }, 
    anoPermitidoInicio() {
        return parseInt(moment().format('YYYY'))-2;
    }, 
    anoPermitidoFim() {
        return parseInt(moment().format('YYYY'))+2;
    },
    addUnicas() {
      this.value.unicas.push({
        id: "",
        flgTipoComp: "UN",
        descricao: "",
        valor: "",
      });
      if (this.flgValorUnico) this.replicaValoresOpcoes(this.value.valorUnico); 
    },
    removeUnicas(index) {
      this.value.unicas.splice(index, 1);
    }, 
    addCasadinhas() {
      this.value.casadinhas.push({
        id: "",
        flgTipoComp: "CA",
        descricao: "",
        valor: "",
      });
      if (this.flgValorUnico) this.replicaValoresOpcoes(this.value.valorUnico); 
    },
    removeCasadinhas(index) {
      this.value.casadinhas.splice(index, 1);
    }, 
    async setModeloPadrao(){
      this.value.flgTipoComp = 'UN';
      this.value.unicas = [
            "sócio do CCG e federado a FBCMGOLFE",
            "sócio do CCG e não federado a FBCMGOLFE",
            "não sócio do CCG e federado a FBCMGOLFE",
            "não sócio do CCG e não federado a FBCMGOLFE",
          ].map((item) => ({
          id: "",          // ou use qualquer lógica apropriada para gerar IDs
          flgTipoComp: "UN",
          descricao: item,
          valor: ""
      }));
      this.replicaValoresOpcoes(this.value.valorUnico);
    },
  },
  mounted() {
    this.flgValorUnico = (this.value.flgValorUnico === 'S' ? true : false)
  }
}
</script>

<style scoped>

</style>